import deleteIcon from "assets/icons/delete_icon.svg";
import { TabType } from "pages/createAds/CreateAds.types";
import CreateFormStateTypes from "pages/createAds/CreateAdsFormValidationSchema";
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

interface PropsInterface {
  asin: string,
  applyTo: string,
  campTabType: TabType
}

const SelectedNegativeProductsRow: FC<PropsInterface> = ({ asin, applyTo, campTabType }) => {
  /**-Hook Form-**/
  const { control } = useFormContext<CreateFormStateTypes>()
  const { remove, fields } = useFieldArray({ control: control, name: `${campTabType}.negativeProducts.expression` })

  /**-Event Handlers-**/
  const handleRemoveAsinClick = (asinToRemove) => {
    const findAsinIndex = fields?.findIndex(asin => asin.value === asinToRemove)
    console.log("findAsinIndex", findAsinIndex)
    remove(findAsinIndex)
  }

  return (
    <div className="grid grid-cols-3">
      <div className="text-[#111827] font-normal leading-6 text-[16px] uppercase">
        {asin}
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px] capitalize">
        {
          applyTo === "adGroup"
            ?
            "Ad Group"
            :
            applyTo
        }
      </div>
      <div className="text-end">
        <button type="button"
          className="text-[#9CA3AF] font-normal leading-6 text-sm p-1 rounded-full hover:bg-rose-400/50 mr-2"
          onClick={() => handleRemoveAsinClick(asin)}
        >
          <img src={deleteIcon} className="w-[23px] " alt="delete" />
        </button>
      </div>
    </div>
  );
};

export default SelectedNegativeProductsRow;
