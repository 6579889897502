import styled from "@emotion/styled";
import { CiSearch } from "react-icons/ci";
import ProductRow from "./ProductRow";
import SelectedIndividualProductsRow from "./SelectedIndividualProductsRow";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import CreateFormStateTypes, { ProductTargetingCluasesInterface, ProductMatchTypesEnums, ProductTargetingExpressionsTypes } from "pages/createAds/CreateAdsFormValidationSchema";
import { useEffect, useState } from "react";
import { DefaultBid } from "constants/index";
import { useProductRecommendationByAsinQuery } from "features/campaign/campaignSlice";

const ContentBoxContainer = styled("div")(() => ({
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#aaa",
  },
}));

export interface ProductInterface {
  id: string,
  name: string,
  author: string,
  asin: string,
  type?: string
}

// const products: ProductInterface[] = [
//   {
//     id: "1",
//     name: "Food & Snacks Coloring Book: Bold & Lorem ipsum dolor sit amet.",
//     author: "Megan Miles Art",
//     asin: "B0DNFFDJNN"
//   },
//   {
//     id: "2",
//     name: "Savory Bites & Sweets: A Food Lover's Coloring Book.",
//     author: "Johnny Wang Art",
//     asin: "B0D4NMW835"
//   },
//   {
//     id: "3",
//     name: "Tasty Treats & Snack Delights: A Creative Coloring Adventure.",
//     author: "Megan Miles Art",
//     asin: "B0DP7DTP2D"
//   },
//   {
//     id: "4",
//     name: "Culinary Creations: A Deliciously Fun Coloring Book.",
//     author: "Mark Wang Art",
//     asin: "B0DP5G3V61"
//   },
// ]

export type IndProdTargetFilterOptionsType = {
  name: string,
  type: ProductMatchTypesEnums
}

const IndProdTargetFilterOptions: IndProdTargetFilterOptionsType[] = Object.keys(ProductMatchTypesEnums).map(key => ({ name: key, type: ProductMatchTypesEnums[key] }))

const IndividualProductsTab = () => {
  /**-React Hooks-**/
  //states
  const [productsWithTypes, setProductsWithTypes] = useState<ProductInterface[]>([])

  /**-RTK-**/
  const { data: recommendedProductsData } = useProductRecommendationByAsinQuery(
    {
      "adAsins": ["B07S2ZB4DL"]
    }
  )

  /**-Hooks Form-**/
  const { watch, control, getValues } = useFormContext<CreateFormStateTypes>()
  const { append, remove } = useFieldArray({
    control: control,
    name: "manualCampaign.targetingClauses"
  })
  const productTargetingFilters = watch("productMatchTypesFilters")
  const allTargetingClauses = watch("manualCampaign.targetingClauses") || []
  const productTargets = allTargetingClauses?.filter(target => target?.expression?.[0].type === ProductTargetingExpressionsTypes.exact || target?.expression?.[0].type === ProductTargetingExpressionsTypes.expanded)

  /**-UseEffects-**/
  useEffect(() => {
    let productsCopy: ProductInterface[] = []

    if (recommendedProductsData?.result?.recommendations?.length) {
      const recommendedAsins = recommendedProductsData?.result?.recommendations
      const duplicateAsinsRemoved = [...new Set(recommendedAsins)]
      const products: ProductInterface[] = duplicateAsinsRemoved?.map((asin, index) => {
        const product = {
          id: `${index + 1}`,
          name: "Book Title",
          author: "Author Name",
          asin: asin as string
        }
        return product
      })

      if (productTargetingFilters?.length) {
        productsCopy = productTargetingFilters
          .filter(Boolean)
          .flatMap((filter) => products?.map(product => ({ ...product, type: filter })))
          .sort((a, b) => {
            // First, comparing id to group objects with the same id together
            if (a?.id < b?.id) return -1;
            if (a?.id > b?.id) return 1;

            // If 'id' is the same, comparing 'type' to prioritize "exact" over "expanded"
            if (a?.type === "exact" && b?.type !== "exact") return -1;
            if (a?.type === "expanded" && b?.type !== "expanded") return 1;

            return 0;
          });
      }

    }
    setProductsWithTypes(productsCopy)

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(productTargetingFilters), productTargetingFilters, recommendedProductsData])

  /**-Event handlers-**/
  const handleProductSelectionChange = (product: ProductInterface): void => {
    const allSelectedTargets = getValues("manualCampaign.targetingClauses")
    const productFound = allSelectedTargets?.some(prod => prod?.id === product?.id && product?.asin === prod?.expression?.[0]?.value && ProductTargetingExpressionsTypes[`${product?.type}`] === prod?.expression?.[0]?.type)
    if (productFound) {
      const index = allSelectedTargets?.findIndex(prod => prod?.id === product?.id && product?.asin === prod?.expression?.[0]?.value && ProductTargetingExpressionsTypes[`${product?.type}`] === prod?.expression?.[0]?.type)
      remove(index)
    } else {
      let bid = Number(getValues("manualCampaign.customBid")) || Number(getValues("manualCampaign.defaultBid")) || DefaultBid
      const multiplierPath = `manualCampaign.keywordTargetsMultiplier.product.value` as keyof CreateFormStateTypes;
      const multiplier2 = Number(getValues(multiplierPath))
      const newProduct: ProductTargetingCluasesInterface = {
        id: product?.id,
        name: product?.name,
        expression: [{ type: ProductTargetingExpressionsTypes[`${product?.type}`], value: product?.asin }],
        bid: bid
      }
      if (multiplier2) {
        bid = bid * (multiplier2 || 1)
        newProduct.bid = bid
      }
      append(newProduct)
    }
  }

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 items-end">
      {/* left box */}
      <div className="flex flex-col gap-2 h-full ">
        {/* box header */}
        <div className="w-full flex flex-col gap-2">
          {/* type input */}
          <div className="flex gap-x-6 items-center flex-wrap">
            {/* type selection */}
            <div className="w-full flex flex-col gap-2">
              <h4 className="text-[#111827] leading-6 font-bold text-[16px] !m-0">
                Types
              </h4>
              <div className="flex gap-5">
                {
                  IndProdTargetFilterOptions?.map((filter, index) => (
                    <Controller
                      key={index}
                      control={control}
                      name={`productMatchTypesFilters.${index}`}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <div className="flex items-center justify-start gap-2">
                            <input
                              id={`productMatchTypesFilters.${index}`}
                              type="checkbox"
                              className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  onChange(filter?.name?.toLowerCase())
                                } else {
                                  onChange("")
                                }
                              }}
                              checked={value ? true : false}
                            />
                            <label htmlFor={`productMatchTypesFilters.${index}`} className="!text-[#111827] text-[16px] !font-normal leading-6 capitalize">
                              {filter?.name}
                            </label>
                          </div>
                        )
                      }}
                    />
                  ))
                }
              </div>
            </div>
          </div>
          {/* search input */}
          <div className="flex gap-x-6 items-center relative ">
            <input
              className="w-full outline-none border-[1px] py-2 px-4 pr-9 rounded-[4px]"
              placeholder="Search categories..."
            />
            <span className="absolute right-0 top-[50%] translate-y-[-50%] px-3 text-[#737373]">
              <CiSearch className="h-5 w-5" />
            </span>
          </div>
        </div>
        {/* keywords inputs button */}
        <div className="w-full flex flex-col gap-4">
          <div className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 h-[200px] overflow-x-hidden overflow-y-auto flex flex-col gap-2">
            {/* row header */}
            <div className="grid grid-cols-5 gap-2">
              <div className=" col-span-2 text-[#9CA3AF] font-medium leading-6 text-[16px]">
                Products
              </div>
              <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                Type
              </div>
              <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                Marketplace
              </div>
              <div className="text-[#9CA3AF] font-medium leading-6 text-[16px] text-center">
                Add All
              </div>
            </div>
            <ContentBoxContainer className="w-full flex-grow flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
              {/* row 1: normal */}
              {
                productsWithTypes?.map((product, index) => (
                  <ProductRow
                    key={index}
                    product={product}
                    handleProductSelectionChange={handleProductSelectionChange}
                  />
                ))
              }
            </ContentBoxContainer>
          </div>
        </div>
      </div>
      {/* right blox */}
      <div className="flex flex-col gap-2 h-full items-end justify-end">
        <div className="w-full flex justify-between">
          <p className="text-[#6B7280] text-[16px] !font-medium !m-0 leading-6">
            {productTargets ? productTargets?.length : 0}/1000 category and product added
          </p>
          <button
            type="button"
            className="text-[#D1D5DB] text-[16px] !font-medium leading-6"
          >
            Remove all
          </button>
        </div>
        {/* keywords inputs button */}
        <div className="w-full flex flex-col">
          <div className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 h-[200px] overflow-x-hidden overflow-y-auto flex flex-col gap-2">
            {/* row header */}
            <div className="grid grid-cols-5">
              <div className=" col-span-2 text-[#9CA3AF] font-medium leading-6 text-[16px]">
                Category & Product
              </div>
              <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                Type
              </div>
              <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                Marketplace
              </div>
            </div>
            <ContentBoxContainer className="w-full flex-grow flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
              {/* content type  */}
              <div className="w-full text-[#9CA3AF] font-medium leading-6 text-xs">
                {false ? "Category" : "Product"}
              </div>
              {
                productTargets?.map((prod, index) => (
                  <SelectedIndividualProductsRow
                    key={index}
                    product={prod}
                  />
                ))
              }
            </ContentBoxContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualProductsTab;
