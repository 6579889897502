import { FC } from "react";
import AdGroupNameInput from "../shared/adGroupNameInput/AdGroupNameInput";
import BidSettings from "../shared/bidSettings/BidSettings";
import CampaignNameInput from "../shared/campaignNameInput/CampaignNameInput";
import CampaignStructureOptions from "../shared/campaignStructureOption/CampaignStructureOptions";
import DropdownInputs from "../shared/dropdownInputs/DropdownInputs";
import MatchTypeInputs from "../shared/matchTypeInputs/MatchTypeInputs";
import PlacementInputs from "../shared/placementInputs/PlacementInputs";
import SelectMarketPlacesInputs from "../shared/selectMarketPlacesInputs/SelectMarketPlacesInputs";

import NegativeTargetingInputs from "../shared/negativeTargetingInputs/NegativeTargetingInputs";
import { TabType } from "../CreateAds.types";
import { marketplaces } from "../data";

interface Props {
  tabType: TabType;
}
const CreateAdsAutoCampaignTab: FC<Props> = ({ tabType }) => {
  return (
    <div className="flex flex-col gap-5">
      {/* campaign name input */}
      <CampaignNameInput
        tabType={tabType}
      />
      {/* ad group name */}
      <AdGroupNameInput
        tabType={tabType}
      />
      {/* campaign structure option */}
      <CampaignStructureOptions
        tabType={tabType}
      />
      {/* dropdown select options */}
      <DropdownInputs
        tabType={tabType}
      />
      {/* divider */}
      <div className="w-full h-[4px] bg-[#E5E7EB]" />
      {/* bid settings */}
      <BidSettings
        tabType={tabType}
      />
      {/* match types */}
      <MatchTypeInputs
        tabType={tabType}
      />
      {/* divider */}
      <div className="w-full h-[4px] bg-[#E5E7EB]" />
      {/* placement inputs */}
      <PlacementInputs
        tabType={tabType}
      />
      {/* divider */}
      <div className="w-full h-[4px] bg-[#E5E7EB]" />
      {/* marketplaces */}
      <SelectMarketPlacesInputs
        tabType={tabType}
        marketplace={marketplaces}
      />
      {/* divider */}
      <div className="w-full h-[4px] bg-[#E5E7EB]" />
      {/* target inputs */}
      <NegativeTargetingInputs
        tabType={tabType}
      />
    </div>
  );
};

export default CreateAdsAutoCampaignTab;
