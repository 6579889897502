import styled from "@emotion/styled";
import { Radio } from "@mui/material";
import SelectedNegativeKeywordRow from "./SelectedNegativeKeywordRow";
import { FC } from "react";
import { TabType } from "pages/createAds/CreateAds.types";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { NegativeKeywordMatchTypeEnums, NegativeApplyToEnums } from "pages/createAds/CreateAdsFormValidationSchema";
import NegativeTargetingTextarea from "../NegativeTargetingTextarea";
import stringToArray from "utils/stringToArray";
import getKeyByValue from "utils/getKeyByValue";

const ContentBoxContainer = styled("div")(() => ({
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#aaa",
  },
}));

const matchTypeOptions = [{ label: "Exact", value: NegativeKeywordMatchTypeEnums.exact }, { label: "Phrase", value: NegativeKeywordMatchTypeEnums.phrase }]
const applyToOptions = [{ label: "Campaign", value: NegativeApplyToEnums.campaign }, { label: "Ad Group", value: NegativeApplyToEnums.adGroup }]

const NegativeKeywordsTab: FC<{ campTabType: TabType }> = ({ campTabType }) => {
  /**-Hook Form-**/
  const { control, getValues, resetField, watch } = useFormContext()
  const { append } = useFieldArray({ control: control, name: `${campTabType}.negativeKeywords.keywordText` })
  const negativeKeywords = watch(`${campTabType}.negativeKeywords`)

  /**-Event Handlers-**/
  const handleAddKeywordClick = () => {
    const createAdsFormValues = getValues()
    const campaign = createAdsFormValues[`${campTabType}`] // auto or manual campaign
    const keywordString: string = campaign?.negativeTargetingInputs?.keyword || ''
    const prevNegTargetingKeywords = campaign?.negativeKeywords?.keywordText || [] //previously added asins to compare with current asins

    const keywords = stringToArray("keyword", keywordString, prevNegTargetingKeywords)
    append(keywords)
    resetField(`${campTabType}.negativeTargetingInputs.keyword`)
  }

  return (

    <div className="grid gap-3">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-end">
        <div className="flex gap-x-6 items-center flex-wrap">
          {/* match type radio button */}
          <div className="inline-flex flex-col gap-2">
            {/* header */}
            <h4 className="text-[#111827] !font-bold !m-0 leading-6 text-[16px]">
              Match Type
            </h4>
            {/* radio button */}
            <div className="flex gap-4">
              {/* exact radio select button */}
              {
                matchTypeOptions?.map(matchType => (
                  <div key={matchType.label} className="flex items-center justify-start">
                    <Controller
                      control={control}
                      name={`${campTabType}.negativeKeywords.matchType`}
                      render={({ field: { onChange, value } }) => {
                        console.log("neKwMatchType", value, matchType?.value)
                        return (
                          <Radio
                            id={`${campTabType}.${matchType?.label}`}
                            sx={{
                              color: "#6B7280",
                              padding: "0px",
                              margin: "0px",
                              marginRight: "5px",
                              "&.Mui-checked": {
                                color: "#237804",
                              },
                            }}
                            value={matchType?.value}
                            onChange={onChange}
                            checked={value === matchType?.value ? true : false}
                          />
                        )
                      }}
                    />
                    <label htmlFor={`${campTabType}.${matchType?.label}`}>{matchType?.label}</label>
                  </div>
                ))
              }
            </div>
          </div>
          {/* apply to radio and inputs */}
          <div className="flex flex-col gap-2">
            {/* header */}
            <h4 className="text-[#111827] !font-bold !m-0 leading-6 text-[16px]">
              Apply to
            </h4>
            {/* radio button */}
            <div className="flex gap-4 items-center">
              {
                applyToOptions?.map(applyTo => (
                  <div key={applyTo.label} className="flex items-center justify-start">
                    <Controller
                      control={control}
                      name={`${campTabType}.negativeKeywords.applyTo`}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Radio
                            id={`${campTabType}.negativeTargetingFilters.keyword.applyTo.${applyTo?.label}`}
                            sx={{
                              color: "#6B7280",
                              padding: "0px",
                              margin: "0px",
                              marginRight: "5px",
                              "&.Mui-checked": {
                                color: "#237804",
                              },
                            }}
                            onChange={onChange}
                            value={applyTo?.value}
                            checked={value === applyTo?.value ? true : false}
                          />
                        )
                      }}
                    />
                    <label htmlFor={`${campTabType}.negativeTargetingFilters.keyword.applyTo.${applyTo?.label}`}>{applyTo?.label}</label>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between">
          <p className="text-[#6B7280] text-[16px] !font-medium !m-0 leading-6">
            {negativeKeywords?.keywordText?.length || 0} keyword added
          </p>
          <button
            type="button"
            className="text-[#D1D5DB] text-[16px] !font-medium leading-6"
          >
            Remove all
          </button>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 items-end">
        {/* left box */}
        <div className="flex flex-col gap-2 h-full ">
          {/* keywords inputs button */}
          <div className="w-full flex flex-col gap-4">
            <NegativeTargetingTextarea
              campTabType={campTabType}
              targetingType="keyword"
            />

            {/* keyword  add info for small screen */}
            <div className="flex">
              <div className="w-full flex justify-between">
                <p>You can enter up to 1000 keywords</p>
                <button
                  type="button"
                  className="text-[#237804] bg-[#CAFCD0] rounded-[3px] py-2 px-4 font-bold"
                  onClick={handleAddKeywordClick}
                >
                  Add Keyword
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* right blox */}
        <div className="flex flex-col gap-2 h-full">
          {/* keywords inputs button */}
          <div className="w-full flex flex-col">
            <div className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 h-[200px] overflow-x-hidden overflow-y-auto flex flex-col gap-2">
              {/* row header */}
              <div className="grid grid-cols-4">
                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Keyword
                </div>
                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Match Type
                </div>
                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Apply to
                </div>
              </div>
              <ContentBoxContainer className="w-full flex-grow flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
                {
                  negativeKeywords?.keywordText?.map((keyword, index) => (
                    <SelectedNegativeKeywordRow
                      key={index}
                      campTabType={campTabType}
                      keyword={keyword}
                      matchType={getKeyByValue(NegativeKeywordMatchTypeEnums, negativeKeywords?.matchType || "")}
                      applyTo={getKeyByValue(NegativeApplyToEnums, negativeKeywords?.applyTo || "")}
                    />
                  ))
                }
              </ContentBoxContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NegativeKeywordsTab;
