type StringToArrayType = (negTargetType: "keyword" | "product", textString: string, previousEntries: any) => string[];

const stringToArray: StringToArrayType = (negTargetType, textString, previousEntries) => {
    let entries: string[] = textString?.split(/,|\n/).map(keyword => keyword.trim().toLocaleLowerCase()).filter(keyword => keyword.length > 0) || [] // string to asins array comma seperated and new line asins
    entries = entries.length ? [...new Set(entries)] : entries // removing the duplicate entries of any case

    if (negTargetType === "product" && previousEntries?.length) {
        //removing duplicate entries by comparing with the previously added entries
        entries = entries.filter(asin =>
            !previousEntries.some(exp => exp?.value?.toLocaleLowerCase() === asin.toLocaleLowerCase())
        )
    }
    if (negTargetType === "keyword" && previousEntries?.length) {
        //removing duplicate entries by comparing with the previously added entries
        entries = entries.filter(asin => !previousEntries.some(keyword => keyword?.toLocaleLowerCase() === asin.toLocaleLowerCase()))
    }

    return entries
}

export default stringToArray