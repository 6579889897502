import deleteIcon from "assets/icons/delete_icon.svg";
import { TabType } from "pages/createAds/CreateAds.types";
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

interface PropsInterface {
  keyword: string,
  matchType?: string,
  applyTo?: string,
  campTabType: TabType
}

const SelectedNegativeKeywordRow: FC<PropsInterface> = ({ keyword, matchType, applyTo, campTabType }) => {
  /**-Hook Form-**/
  const { control, getValues } = useFormContext()
  const { remove } = useFieldArray({ control: control, name: `${campTabType}.negativeKeywords.keywordText` })

  /**-Event Handlers-**/
  const handleKeywordRemoveClick = (kwToDelete) => {
    const keywordIndexFound = getValues(`${campTabType}.negativeKeywords.keywordText`)?.findIndex(keyword => keyword === kwToDelete)
    remove(keywordIndexFound)
  }

  return (
    <div className="grid grid-cols-4">
      <div className="text-[#111827] font-normal leading-6 text-[16px]">
        {keyword}
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px] capitalize">
        {matchType}
      </div>
      <div className="text-[#111827] font-normal leading-6 text-[16px] capitalize">
        {applyTo}
      </div>
      <div className="text-end">
        <button
          type="button"
          className="text-[#9CA3AF] font-normal leading-6 text-sm p-1 rounded-full hover:bg-rose-400/50 mr-2"
          onClick={() => handleKeywordRemoveClick(keyword)}
        >
          <img src={deleteIcon} className="w-[25px] " alt="delete" />
        </button>
      </div>
    </div>
  );
};

export default SelectedNegativeKeywordRow;
