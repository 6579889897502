import SubmitButton from "components/submitButton/SubmitButton";
import { IoSyncSharp } from "react-icons/io5";
import { MdChevronLeft } from "react-icons/md";
import cn from "utils/cn";
import { TabType } from "./CreateAds.types";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import CreateAdsAutoCampaignTab from "./createAdsAutoCampaignTab/CreateAdsAutoCampaignTab";
import CreateAdsManualCampaignTab from "./createAdsManualCampaignTab/CreateAdsManualCampaignTab";
import Warning from "./Warning";
import CreateFormStateTypes, { AutoCampaignFormSchema, AutoMatchTypeEnums, createAdsValidationSchema, ManualCampaignFormSchema, NegativeKeywordMatchTypeEnums, NegativeApplyToEnums } from "./CreateAdsFormValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateCampaignMutation } from "features/campaign/campaignSlice";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useLoggedInUserQuery } from "features/user/userSlice";

type FilledForType = "Auto" | "Manual" | "Both"

type StructureType = { camp?: [string], camDynamic: boolean, adGrpDynamic: boolean, adGroup?: [string] }
interface CampStructureMapInterface { //this structure defines how many campaigns and adgroup needed based on campaign structure in each targatingType(i.e. AUTO & MANUAL)
  SingleCampaignWithSingleAdgroup: StructureType,
  SingleCampaignWithMultipleAdgroup: StructureType,
  MultipleCampaignWithSingleAdgroup: StructureType,
}

const campStructureMap: CampStructureMapInterface = {
  SingleCampaignWithSingleAdgroup: { camp: ["oneCamp"], camDynamic: false, adGrpDynamic: false, adGroup: ["allMatchTypes"] },
  SingleCampaignWithMultipleAdgroup: { camp: ["oneCamp"], camDynamic: false, adGrpDynamic: true },
  MultipleCampaignWithSingleAdgroup: { camDynamic: true, adGrpDynamic: false, adGroup: ["allMatchTypes"] },
}


const CreateAds = () => {
  /**-React Hooks-**/
  //states
  const [activeTab, setActiveTab] = useState<TabType>(TabType.AUTO_CAMPAIGN);
  const [warningOpen, setWarningOpen] = useState<boolean>(false);
  const [dataFilledFor, setDataFilledFor] = useState<"Auto" | "Manual" | "">("")

  const { data: logUserData } = useLoggedInUserQuery({});
  console.info("user log data", logUserData?.result)
  /**-RTK-**/
  //mutations
  const [createCampaign, { isLoading: createCampaignLoading }] = useCreateCampaignMutation()

  /* Hook form */
  const methods = useForm<CreateFormStateTypes>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      keywordMatchTypesFilters: { broad: true, phrase: true, exact: true },
      productMatchTypesFilters: ["exact", "expanded"],
      autoCampaign: {
        campaignName: {
          placeholders: [],
        },
        adGroupName: {
          placeholders: [],
        },
        campaignStructureType: "SingleCampaignWithSingleAdgroup",
        budget: {
          budgetType: "DAILY"
        },
        autoCampaignMatchTypes: [
          { expression: AutoMatchTypeEnums.close, },
          { expression: AutoMatchTypeEnums.loose, },
          { expression: AutoMatchTypeEnums.substitues, },
          { expression: AutoMatchTypeEnums.complements, },
        ],
        // negativeTargetingFilters: {
        //   keyword: { matchType: NegativeKeywordMatchTypeEnums.exact },
        // },
        negativeKeywords: {
          applyTo: NegativeApplyToEnums.campaign,
          matchType: NegativeKeywordMatchTypeEnums.exact
        },
        negativeProducts: {
          applyTo: NegativeApplyToEnums.campaign
        },
        noEndDate: false,
      },
      manualCampaign: {
        types: { keywordsTargeting: true, productTargeting: true },
        campaignName: {
          placeholders: [],
        },
        adGroupName: {
          placeholders: [],
        },
        campaignStructureType: "SingleCampaignWithSingleAdgroup",
        budget: {
          budgetType: "DAILY"
        },
        noEndDate: false,
        keywordTargetsMultiplier: {
          broad: { selected: true },
          phrase: { selected: true },
          exact: { selected: true },
          product: { selected: true },
          category: { selected: true }
        },
        // negativeTargetingFilters: {
        //   keyword: { matchType: NegativeKeywordMatchTypeEnums.exact, applyTo: NegativeApplyToEnums.campaign },
        // },
        negativeKeywords: {
          applyTo: NegativeApplyToEnums.campaign,
          matchType: NegativeKeywordMatchTypeEnums.exact
        },
        negativeProducts: {
          applyTo: NegativeApplyToEnums.campaign
        }
      },
    },
    resolver: yupResolver(createAdsValidationSchema)
  });

  const { getValues, reset: resetFullCampForm, formState: { errors } } = methods

  /**--Event handlers --**/
  const handleCreateAddSubmit: SubmitHandler<CreateFormStateTypes> = (data) => {
    // handleContinueCreatingCampaign("Both")
    console.info(data)
  };

  // const handleContinueCreatingCampaign = async (filledFor: FilledForType) => {
  //   const formData = getValues()
  //   let allCampaigns: { campaigns: any } = { campaigns: [] }
  //   console.log(filledFor, formData)
  //   if (filledFor === "Auto") {
  //     const autoCampaignData = formData?.autoCampaign
  //     const { campaignStructureType } = autoCampaignData
  //     const autoCampMatchTypes = autoCampaignData?.autoCampaignMatchTypes?.filter(matchType => matchType?.expression && matchType?.bid)
  //     const campStructure = generateStrucForDuplicatingCampAndAdGroup(autoCampMatchTypes, campaignStructureType)
  //     const campaigns = createAutoCampPayload(campStructure, autoCampaignData)
  //     allCampaigns.campaigns = allCampaigns?.campaigns?.concat(campaigns)

  //   } else if (filledFor === "Manual") {
  //     const manualCampaignData = formData?.manualCampaign
  //     const { campaignStructureType } = manualCampaignData
  //     if (manualCampaignData?.keywordTargets?.length) {
  //       const keywordMatchTypes = ["BROAD", "PHRASE", "EXACT"]
  //       const totalKeywordMatchTypesFound = keywordMatchTypes.map(matchType =>
  //         manualCampaignData?.keywordTargets?.find(target => target?.matchType === matchType)
  //       ).filter(Boolean)
  //       const campStructure = generateStrucForDuplicatingCampAndAdGroup(totalKeywordMatchTypesFound, campaignStructureType)
  //       const campaigns = createManualCampPayload(campStructure, manualCampaignData, "KEYWORD")
  //       allCampaigns.campaigns = allCampaigns?.campaigns?.concat(campaigns)
  //     }
  //     if (manualCampaignData?.targetingClauses?.length) {
  //       const category = manualCampaignData?.targetingClauses?.find(clause => clause?.expression?.[0]?.type === "ASIN_CATEGORY_SAME_AS")
  //       const exact = manualCampaignData?.targetingClauses?.find(clause => clause?.expression?.[0]?.type === "ASIN_SAME_AS")
  //       const expanded = manualCampaignData?.targetingClauses?.find(clause => clause?.expression?.[0]?.type === "ASIN_EXPANDED_FROM")
  //       const allMatchTypes = [category, exact, expanded]
  //       const manualMatchTypes = allMatchTypes?.filter(matchType => matchType)
  //       const campStructure = generateStrucForDuplicatingCampAndAdGroup(manualMatchTypes, campaignStructureType)
  //       const campaigns = createManualCampPayload(campStructure, manualCampaignData, "PRODUCT")
  //       allCampaigns.campaigns = allCampaigns?.campaigns?.concat(campaigns)
  //     }
  //   } else {

  //   }

  //   //Final API call.
  //   if (allCampaigns?.campaigns?.length) {
  //     console.log("totalCampaigns", allCampaigns)
  //     try {
  //       const createdCampaign = await createCampaign(allCampaigns).unwrap()
  //       if (createdCampaign?.success) {
  //         setWarningOpen(false)
  //         resetFullCampForm()
  //       }
  //     } catch (error) {
  //       console.log("autoCampaign", formData?.autoCampaign)
  //       console.error("campaignCreationErrors", error)
  //     }
  //   } else {
  //     console.log("Auto campaigns validation errors: ", errors?.autoCampaign)
  //     console.log("Manual campaigns validation errors: ", errors?.manualCampaign)
  //   }
  // }

  const handleContinueCreatingCampaign = async (filledFor: FilledForType) => {
    const formData = getValues()
    let allCampaigns: { campaigns: any } = { campaigns: [] }

    const generateAutoCampaigns = (autoCampData: AutoCampaignFormSchema) => {
      const { campaignStructureType, autoCampaignMatchTypes } = autoCampData
      const matchTypes = autoCampaignMatchTypes?.filter(matchType => matchType?.expression && matchType?.bid)
      const campStructure = generateStrucForDuplicatingCampAndAdGroup(matchTypes, campaignStructureType)
      return createAutoCampPayload(campStructure, autoCampData)
    }

    if (filledFor === "Auto" || filledFor === "Both") {
      const autoCampaignData = formData?.autoCampaign
      const autoCampaigns = generateAutoCampaigns(autoCampaignData)
      allCampaigns.campaigns = allCampaigns?.campaigns?.concat(autoCampaigns)
    }

    if (filledFor === "Manual" || filledFor === "Both") {
      const manualCampaignData = formData?.manualCampaign
      const { campaignStructureType } = manualCampaignData

      if (manualCampaignData?.keywordTargets?.length) {
        const keywordMatchTypes = ["BROAD", "PHRASE", "EXACT"]
        const totalKeywordMatchTypesFound = keywordMatchTypes.map(matchType =>
          manualCampaignData?.keywordTargets?.find(target => target?.matchType === matchType)
        ).filter(Boolean)
        const campStructure = generateStrucForDuplicatingCampAndAdGroup(totalKeywordMatchTypesFound, campaignStructureType)
        const campaigns = createManualCampPayload(campStructure, manualCampaignData, "KEYWORD")
        allCampaigns.campaigns = allCampaigns?.campaigns?.concat(campaigns)
      }

      if (manualCampaignData?.targetingClauses?.length) {
        const category = manualCampaignData?.targetingClauses?.find(clause => clause?.expression?.[0]?.type === "ASIN_CATEGORY_SAME_AS")
        const exact = manualCampaignData?.targetingClauses?.find(clause => clause?.expression?.[0]?.type === "ASIN_SAME_AS")
        const expanded = manualCampaignData?.targetingClauses?.find(clause => clause?.expression?.[0]?.type === "ASIN_EXPANDED_FROM")
        const allMatchTypes = [category, exact, expanded]
        const manualMatchTypes = allMatchTypes?.filter(matchType => matchType)
        const campStructure = generateStrucForDuplicatingCampAndAdGroup(manualMatchTypes, campaignStructureType)
        const campaigns = createManualCampPayload(campStructure, manualCampaignData, "PRODUCT")
        allCampaigns.campaigns = allCampaigns?.campaigns?.concat(campaigns)
      }
    }

    // Final API call
    if (allCampaigns?.campaigns?.length) {
      try {
        const createdCampaign = await createCampaign(allCampaigns).unwrap()
        if (createdCampaign?.success) {
          setWarningOpen(false)
          resetFullCampForm()
          toast(`${createdCampaign?.message}`, {
            type: "success",
            autoClose: 3000
          })
        } else {
          toast(`${createdCampaign?.message}`, {
            type: "error",
            autoClose: 3000
          })
        }
      } catch (error: any) {
        console.log("autoCampaign", formData?.autoCampaign)
        console.error("campaignCreationErrors", error)
        toast(
          `${JSON.stringify(error?.data?.validationErrors)}`,
          { type: "error", autoClose: 3000 }
        )
      }
    } else {
      console.log("Auto campaigns validation errors: ", errors?.autoCampaign)
      console.log("Manual campaigns validation errors: ", errors?.manualCampaign)
    }
  }


  /**
    This function generates a structure for duplicating campaign and adGroup 
    based on selected campaign structure and selected match type 
  */
  const generateStrucForDuplicatingCampAndAdGroup = (matchTypes: any, structure: string) => {
    const newCampStrucMap: StructureType = { ...campStructureMap[structure as keyof CampStructureMapInterface] };
    if (campStructureMap[structure as keyof CampStructureMapInterface].camDynamic && campStructureMap[structure as keyof CampStructureMapInterface].adGrpDynamic) {
      let camp: any = [];
      let adGroup: any = [];
      matchTypes?.forEach((matchType, i) => {
        camp?.push(`Camp-${i + 1}, ${matchType?.expression}`)
        adGroup?.push(`AdGroup-${i + 1}, ${matchType?.expression}`)
      })
      newCampStrucMap.camp = camp
      newCampStrucMap.adGroup = adGroup
    } else if (campStructureMap[structure as keyof CampStructureMapInterface].adGrpDynamic) {
      let adGroup: any = [];
      matchTypes?.forEach((matchType, i) => {
        adGroup?.push(`AdGroup-${i + 1}, ${matchType?.expression}`)
      })
      newCampStrucMap.adGroup = adGroup
    } else if (campStructureMap[structure as keyof CampStructureMapInterface].camDynamic) {
      let camp: any = [];
      matchTypes?.forEach((matchType, i) => {
        camp?.push(`Camp-${i + 1}, ${matchType?.expression}`)
      })
      newCampStrucMap.camp = camp
    } else {
      console.log("Unknown Campaign Structure Given: ", structure)
    }
    return newCampStrucMap
  }

  const createAutoCampPayload = (campStructure: any, autoCampaignData: AutoCampaignFormSchema) => {
    const campaigns: any = []
    campStructure?.camp?.forEach(() => {
      let autoCampaign: any = {
        targetingType: "AUTO",
        name: autoCampaignData?.campaignName?.name,
        startDate: autoCampaignData?.startDate ? dayjs(autoCampaignData?.startDate).format("YYYY-MM-DD") : null,
        endDate: autoCampaignData?.endDate ? dayjs(autoCampaignData?.endDate).format("YYYY-MM-DD") : null,
        defaultBid: autoCampaignData?.defaultBid,
        customBid: autoCampaignData?.customBid || 0,
        marketPlaces: [
          {
            profileId: "2475159079111070",
            portfolioId: "73874278171636",
            countryCode: "US"
          }
        ],
        dynamicBidding: autoCampaignData?.dynamicBidding ? filterDynamicBidding(autoCampaignData?.dynamicBidding) : null,
        budget: autoCampaignData?.budget,
        autoCampaignMatchTypes: autoCampaignData?.autoCampaignMatchTypes ? filterMatchType(autoCampaignData?.autoCampaignMatchTypes) : null,
        asin: ["B07S2ZB4DL"],
        keywordTargets: [],
        targetingClauses: [],
        negativeKeywords: (autoCampaignData?.negativeKeywords?.keywordText && autoCampaignData?.negativeKeywords?.keywordText?.length > 0) ? autoCampaignData?.negativeKeywords : null,
        negativeProducts: (autoCampaignData?.negativeProducts?.expression && autoCampaignData?.negativeProducts?.expression?.length > 0) ? autoCampaignData?.negativeProducts : null,
        isCreated: true
      };
      let adGroups: any = []
      campStructure?.adGroup?.forEach(() => {
        const adGroup = {
          name: autoCampaignData?.adGroupName?.name,
          defaultBid: autoCampaignData.defaultBid
        }
        adGroups.push(adGroup)
      })
      autoCampaign.adGroups = adGroups
      campaigns.push(autoCampaign)
    })
    return campaigns
  }

  const createManualCampPayload = (campStructure: any, manualCampaignData: ManualCampaignFormSchema, manualTargetingType: "KEYWORD" | "PRODUCT",) => {
    const campaigns: any = []
    campStructure?.camp?.forEach(() => {
      let autoCampaign: any = {
        targetingType: "MANUAL",
        name: manualCampaignData?.campaignName?.name,
        startDate: manualCampaignData?.startDate ? dayjs(manualCampaignData?.startDate).format("YYYY-MM-DD") : null,
        endDate: manualCampaignData?.endDate ? dayjs(manualCampaignData?.endDate).format("YYYY-MM-DD") : null,
        defaultBid: manualCampaignData?.defaultBid,
        customBid: manualCampaignData?.customBid || 0,
        // marketPlaces: manualCampaignData?.marketPlaces && manualCampaignData?.marketPlaces?.forEach((d) => {
        //   return { profileId: '', portfolioId: '', countryCode: '' }
        // }),
        marketPlaces: [
          {
            profileId: "2475159079111070",
            portfolioId: "73874278171636",
            countryCode: "US"
          }
        ],
        dynamicBidding: manualCampaignData?.dynamicBidding ? filterDynamicBidding(manualCampaignData?.dynamicBidding) : null,
        budget: manualCampaignData?.budget,
        autoCampaignMatchTypes: [],
        asin: ["B07S2ZB4DL"],
        keywordTargets: manualTargetingType === "KEYWORD" ? (manualCampaignData?.keywordTargets && manualCampaignData?.keywordTargets?.length > 0 ? manualCampaignData?.keywordTargets : []) : [],
        targetingClauses: manualTargetingType === "PRODUCT" ? (manualCampaignData?.targetingClauses && manualCampaignData?.targetingClauses?.length > 0 ? manualCampaignData?.targetingClauses : []) : [],
        negativeKeywords: manualCampaignData?.negativeKeywords?.keywordText && manualCampaignData?.negativeKeywords?.keywordText?.length > 0 ? manualCampaignData?.negativeKeywords : null,
        negativeProducts: null, /** negtiveProdutctTargeting (known as targetingClauses in Amazon) is not allowed in manual campaigns as of 31/12/2024 */
        isCreated: true
      };
      let adGroups: any = []
      campStructure?.adGroup?.forEach(() => {
        const adGroup = {
          name: manualCampaignData?.adGroupName?.name,
          defaultBid: manualCampaignData.defaultBid
        }
        adGroups.push(adGroup)
      })
      autoCampaign.adGroups = adGroups
      campaigns.push(autoCampaign)
    })
    return campaigns
  }

  // Filter match type
  const filterMatchType = (mt: {
    expression?: AutoMatchTypeEnums | undefined;
    bid?: number | null | undefined;
  }[]) => {
    let mtFilter: any[] = []
    mt.forEach((m) => {
      if (typeof m?.bid === 'number' && m.bid > 0) {
        mtFilter.push(m)
      }
    })
    return mtFilter
  }

  // Filter dynamic bidding
  const filterDynamicBidding = (db: {
    placementBidding?: {
      placement?: "PLACEMENT_TOP" | "PLACEMENT_PRODUCT_PAGE" | "PLACEMENT_REST_OF_SEARCH" | undefined;
      percentage?: number | null | undefined;
    }[] | undefined
  }) => {
    // @TODO add logic if needed
    let pbFilter: any[] = []
    if (db.placementBidding) {
      db.placementBidding.forEach((pb) => {
        if (typeof pb.percentage === 'number' && pb.percentage > 0) {
          pbFilter.push(pb)
        }
      })
    }

    return {
      placementBidding: pbFilter,
      "strategy": "LEGACY_FOR_SALES"
    }
  }

  /**--useEffect--**/
  useEffect(() => {
    if (Object.keys(errors).length) {
      //checking manual campaign data is provided or not
      if (activeTab === TabType.AUTO_CAMPAIGN && !errors?.autoCampaign) {
        if (errors?.manualCampaign) {
          setDataFilledFor("Auto")
          setWarningOpen(true) //if manual campaign data is not provided then showing a warning modal
        }
      }
      //checking auto campaign data is provided or not
      if (activeTab === TabType.MANUAL_CAMPAIGN && !errors?.manualCampaign) {
        if (errors?.autoCampaign) {
          setDataFilledFor("Manual")
          setWarningOpen(true) //if auto campaign data is not provided then showing a warning modal
        }
      }
    }
  }, [errors, activeTab])

  console.log("formValues", getValues())
  console.log("formErrors", errors)

  return (
    <div className="flex flex-col gap-4">
      {/* back and sync button */}
      <div className="flex items-center justify-between bg-white py-2 px-3 ">
        {/* back button */}
        <button
          type="button"
          className=" capitalize flex items-center gap-1 text-[#6B7280] text-sm !font-bold leading-5"
        >
          <MdChevronLeft className="h-5 w-5" />
          <span>create ads</span>
        </button>
        <div className="flex items-center gap-2 ">
          <button type="button" className="!font-bold text-[#6B7280]">
            <IoSyncSharp className="h-5 w-5" />
          </button>
          <span className="text-[#6B7280] text-xs !font-bold leading-5 capitalize">
            last app sync
          </span>
          <span className="text-[#6B7280] text-xs !font-normal leading-5">
            Wed, Nov 2, 10:57
          </span>
        </div>
      </div>
      {/* main content box */}
      <FormProvider {...methods}>
        <form onSubmit={methods?.handleSubmit(handleCreateAddSubmit)} className="flex flex-col bg-white rounded-[4px]">
          {/* page switching buttons */}
          <div className="flex flex-row flex-wrap gap-6 border-b  px-4">
            <button
              type="button"
              className={cn(
                "text-[#6B7280] border-b-[3px] border-transparent text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",
                activeTab === TabType.AUTO_CAMPAIGN &&
                " border-[#237804] text-[#111827] "
              )}
              onClick={() => setActiveTab(TabType.AUTO_CAMPAIGN)}
            >
              Auto Campaign
            </button>

            <button
              type="button"
              className={cn(
                "text-[#6B7280] border-b-[3px] border-transparent text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",
                activeTab === TabType.MANUAL_CAMPAIGN &&
                " border-[#237804] text-[#111827] "
              )}
              onClick={() => setActiveTab(TabType.MANUAL_CAMPAIGN)}
            >
              Manual Campaign
            </button>
          </div>
          {/* content */}
          <div className="p-4">
            <div className="flex flex-col gap-5">
              {/* tab content */}
              {activeTab === TabType.AUTO_CAMPAIGN ? (
                <CreateAdsAutoCampaignTab
                  tabType={TabType.AUTO_CAMPAIGN}
                />
              ) : (
                <CreateAdsManualCampaignTab
                  tabType={TabType.MANUAL_CAMPAIGN}
                />
              )}

              {/* form action button */}
              <div className="flex items-center justify-end gap-5">
                <button
                  type="button"
                  className="capitalize py-2 px-8 border-[1px] border-[#237804] text-[#237804] rounded-[4px] !font-bold leading-6 text-[16px]"
                >
                  back
                </button>
                <div>
                  <SubmitButton
                    buttonText="Finish"
                    buttonType="submit"
                    className="px-8"
                    loadingClassName="px-8"
                    disabledClassName="px-8"
                    // isDisabled={!isValid}
                    isLoading={createCampaignLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      {/* warning model */}
      <Warning
        open={warningOpen}
        setOpen={setWarningOpen}
        setActiveTab={setActiveTab}
        dataFilledFor={dataFilledFor}
        handleContinue={() => handleContinueCreatingCampaign(dataFilledFor === "Auto" ? "Auto" : "Manual")}
        isLoading={createCampaignLoading}
      />
    </div>
  );
};

export default CreateAds;
