import { apiSlice } from "features/api/apiSlice";

export const paymentSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPlans: builder.query({
            query: () => `/Plan`,
        }),
        createPaymentUrl: builder.mutation({
            query: (data) => ({
                url: `/PaymentGateway/Stripe/CreateRecurringPaymentUrl`,
                method: "POST",
                body: data
            })
        }),
        makeProSubscription: builder.mutation({
            query: (data) => ({
                url: `/PaymentGateway/Stripe/Subscription/Create`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["getBillingHistory", "loggedInUser"]
        }),
        cancleSubscription: builder.mutation({
            query: (subscriptionId) => ({
                url: `/PaymentGateway/Stripe/Subscription/Cancel`,
                method: "POST",
                body: subscriptionId,
            }),
            invalidatesTags: ["loggedInUser", "getBillingHistory"]
        }),
        addPaymentMethod: builder.mutation({
            query: (cardToken) => ({
                url: `/PaymentGateway/Stripe/card/Add`,
                method: "POST",
                body: cardToken
            }),
            invalidatesTags: ["loggedInUser"]
        }),
        getBillingHistory: builder.query({
            query: (data) => ({
                url: `/BillingAndPlaning/BillingHistory`,
                method: "POST",
                body: data
            }),
            providesTags: ["getBillingHistory"]
        }),
    })
})

export const {
    useGetPlansQuery,
    useCreatePaymentUrlMutation,
    useMakeProSubscriptionMutation,
    useCancleSubscriptionMutation,
    useAddPaymentMethodMutation,
    useGetBillingHistoryQuery
} = paymentSlice;