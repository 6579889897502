/* eslint-disable react/jsx-pascal-case */
import { useMemo, useState } from "react";

//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";

//Material UI Imports
import { Box } from "@mui/material";


//Mock Data
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchInput from "./searchInput/SearchInput";
import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import FilterButton from "./filterButton/FilterButton";
import ColumnButton from "./columnButton/ColumnButton";
import ManageTagsButton from "./manageTagsButton/ManageTagsButton";
import ExportButton from "./exportButton/ExportButton";
import { TableDataType } from "./DataTable.types";
import { mkConfig, generateCsv, download } from "export-to-csv";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const flattenData = (data) => {
  const result: any[] = [];

  const recurse = (items) => {
    items.forEach((item) => {
      const { subRows, ...rest } = item; // Extract subRows
      result.push(rest); // Push the current item without subRows
      if (subRows) {
        recurse(subRows); // Recursively flatten subRows
      }
    });
  };

  recurse(data);
  return result;
};

const DataTable = ({ data }: { data: TableDataType[] }) => {

  /**-- STATE --**/
  // global filter state
  const [globalFilter, setGlobalFilter] = useState<string | null>(null);

  /**-- column state memo hook --*/
  const columns = useMemo<MRT_ColumnDef<TableDataType>[]>(
    () => [
      {
        accessorKey: "bookName", //accessorFn used to join multiple data into a single cell
        id: "name", //id is still required when using accessorFn instead of accessorKey
        header: "Book Details",
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              alt="avatar"
              src={row.original?.bookCoverImage}
              loading="lazy"
              style={{ height: "80px", width: "50px" }}
            />
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <div className="flex flex-col">
              <span className="line-clamp-3">{renderedCellValue}</span>
              <span className="text-gray-600">{row?.original?.asin}</span>
            </div>
          </Box>
        ),
      },
      {
        accessorKey: "targetACOS", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: true,
        filterVariant: "autocomplete",
        header: "Target ACOS%",
        size: 300,
      },

      {
        accessorKey: "bsr",
        // filterVariant: 'range', //if not using filter modes feature, use this instead of filterFn
        filterFn: "between",
        header: "BSR ",
        size: 200,
        //custom conditional format and styling
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue<number>() < 50_000
                  ? theme.palette.error.dark
                  : cell.getValue<number>() >= 50_000 &&
                    cell.getValue<number>() < 75_000
                    ? theme.palette.warning.dark
                    : theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue<number>()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),
      },
      {
        accessorKey: "unit_sold", //hey a simple column for once
        header: "Unit Sold",
        size: 350,
      },
      {
        accessorFn: (row) => new Date(row.startDate), //convert to Date for sorting and filtering
        id: "startDate",
        header: "Start Date",
        filterVariant: "date",
        filterFn: "lessThan",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        muiFilterTextFieldProps: {
          sx: {
            minWidth: "250px",
          },
        },
      },
    ],
    []
  );

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(flattenData(data));
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    // enableColumnOrdering: true,
    enableGrouping: false,
    enableColumnPinning: true,
    // enableExpanding: true,
    enableRowSelection: true,
    enableExpandAll: false,
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? '#fff'
            : '#fff',
      }),
    }),
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    renderDetailPanel: ({ row }: { row: any }) =>
      row ? (
        <Box
          sx={{
            display: 'grid',
            margin: 'auto',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
          }}
        >
          Test
        </Box>
      ) : null,
    initialState: {
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand", "name"],
      },
    },
    state: {
      globalFilter: globalFilter,
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    displayColumnDefOptions: {
      "mrt-row-expand": {
        size: 20,
        grow: false,
      },
      "mrt-row-select": {
        size: 10,
        grow: false,
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
      sx: {
        "& .css-litcis-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
        {
          backgroundColor: "#237804 !important",
          color: "#fff",
        },
        "& .css-5bvx1p-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-5bvx1p-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-5bvx1p-MuiSelect-select-MuiInputBase-input-MuiInput-input":
        {
          color: "#333",
        },
      },
    },
    muiTableBodyProps: {
      sx: {
        width: "100%",
        "& .css-1ucns9m-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
          color: "#237804",
        },
      },
    },
    muiTableHeadRowProps: {
      sx: {
        ".css-1ucns9m-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,.css-1ucns9m-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
        {
          color: "#237804 !important",
        },
      },
    },
    renderTopToolbar: ({ table }) => {
      // const handleDeactivate = () => {
      //   table.getSelectedRowModel().flatRows.forEach((row) => {
      //     alert("deactivating " + row.getValue("name"));
      //   });
      // };

      // const handleActivate = () => {
      //   table.getSelectedRowModel().flatRows.forEach((row) => {
      //     alert("activating " + row.getValue("name"));
      //   });
      // };

      // const handleContact = () => {
      //   table.getSelectedRowModel().flatRows.forEach((row) => {
      //     alert("contact " + row.getValue("name"));
      //   });
      // };

      return (
        <div className="flex lg:items-center justify-between bg-[#fff] px-2 py-2 flex-col lg:flex-row w-full">
          {/* left options */}
          <div className="grid grid-cols-2 gap-6 items-center w-full lg:w-[40%] py-1">
            <SearchInput
              changeHandler={(s) => {
                setGlobalFilter(s);
              }}
            />
            <CustomSelectOption
              field={{}}
              options={[
                {
                  name: "Create Ads",
                  value: "Create Ads",
                },
                {
                  name: "Add Tags",
                  value: "Add Tags",
                },
                {
                  name: "Set Target ACOS",
                  value: "Set Target ACOS",
                },
              ]}
              placeholder="Bulk operation"
              className=""
              sx={{
                "& .MuiSelect-select.MuiSelect-outlined": {
                  padding: "6px 14px !important",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            />
          </div>
          {/* right options */}
          <div className="flex gap-3 items-center py-1">
            <FilterButton />
            <ColumnButton />
            <ManageTagsButton />
            <ExportButton
              handleClick={() => {
                handleExportData();
              }}
            />
          </div>
        </div>
      );
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

export default DataTable;
