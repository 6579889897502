import { TabType } from 'pages/createAds/CreateAds.types';
import CreateFormStateTypes from 'pages/createAds/CreateAdsFormValidationSchema';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

const NegativeTargetingTextarea: FC<{ campTabType: TabType, targetingType: "keyword" | "product" }> = ({ campTabType, targetingType }) => {
    /**-Hook Form-**/
    const { register } = useFormContext<CreateFormStateTypes>()

    return (
        <textarea
            className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 min-h-[200px] resize-none"
            placeholder="Separate keyword with comma (,) or enter new line"
            {...register(`${campTabType}.negativeTargetingInputs.${targetingType}` as keyof CreateFormStateTypes)}
        />
    );
};

export default NegativeTargetingTextarea;