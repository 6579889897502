import { apiSlice } from "features/api/apiSlice";

const adReportSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getBookReports: builder.mutation({
            query: (data) => ({
                url: "/V3/AdReport/books",
                method: "POST",
                body: data
            }),
        })
    })
})


export const { useGetBookReportsMutation } = adReportSlice 