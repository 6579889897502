import ErrorLabel from "components/errorLabel/ErrorLabel";
import { TabType } from "pages/createAds/CreateAds.types";
import CreateFormStateTypes, { AutoMatchTypeEnums } from "pages/createAds/CreateAdsFormValidationSchema";
import { FC } from "react";
import {
  Controller,
  useFormContext,
} from "react-hook-form";
import { BsCurrencyDollar } from "react-icons/bs";
import cn from "utils/cn";

interface Props {
  tabType: TabType;
}

const autoCampaignMatchTypesOptions: { label: string, value: AutoMatchTypeEnums }[] = [
  { label: "Close", value: AutoMatchTypeEnums.close },
  { label: "Loose", value: AutoMatchTypeEnums.loose },
  { label: "Substitutes", value: AutoMatchTypeEnums.substitues },
  { label: "Complements", value: AutoMatchTypeEnums.complements },
]

const MatchTypeInputs: FC<Props> = ({ tabType }) => {
  /**-Hook Form-**/
  const { control, watch } = useFormContext<CreateFormStateTypes>()
  return (
    <div className="w-full flex flex-col gap-2">
      {/* header */}
      <div className="flex flex-col">
        <span className="text-[#111827] leading-6 font-bold text-[16px]">
          Match types
        </span>
        <p className="text-[#111827] !m-0 !font-normal text-[16px] leading-6 ">
          Match type will be multiplied with targeting bid
        </p>
      </div>
      {/* input options */}
      <div
        className={cn(
          "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-6",
          tabType === TabType.AUTO_CAMPAIGN
            ? "xl:grid-cols-5"
            : "xl:grid-cols-6"
        )}
      >
        {/* targeting bid inputs */}
        <Controller
          name={`${tabType}.customBid`}
          control={control}
          render={({ field, fieldState }) => (
            <div className=" flex gap-2 flex-col">
              <h4 className="!m-0 text-[#111827] text-[16px] !font-bold leading-6">
                Targeting Bid (X)
              </h4>
              <div className="w-full relative">
                <span
                  className={cn(
                    "absolute top-[50%] translate-y-[-50%] left-[1px] px-3.5 py-[10px] rounded-l-[4px] bg-[#F3F4F6] text-[#111827] ",
                    fieldState.error && "border-r !border-red-500"
                  )}
                >
                  <BsCurrencyDollar className="h-4 w-4" />
                </span>
                <input
                  onChange={(e) => {
                    field?.onChange(Number(e.target.value));
                  }}
                  value={field.value || undefined}
                  placeholder="Enter default bid"
                  className={cn(
                    "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pl-12 hover:border-[#237804] focus:border-[#237804]",
                    fieldState.error && "!border-red-500"
                  )}
                  type="number"
                  min={0.02}
                  step={0.02}
                />

              </div>
              {fieldState.error?.message && <ErrorLabel message={fieldState?.error?.message} />}
            </div>
          )}
        />
        {/* 
        #######################################################################
        #---------------------------------------------------------
        # auto campaign specific inputs 
        #---------------------------------------------------------
        #######################################################################
        */}

        {
          tabType === TabType.AUTO_CAMPAIGN && (
            <>
              {

                autoCampaignMatchTypesOptions?.map((matchType, index) => (
                  <div key={index} className=" flex gap-2 flex-col">

                    <>
                      <Controller
                        name={`${tabType}.autoCampaignMatchTypes.${index}.expression`}
                        control={control}
                        render={({ field }) => {
                          console.log("autoMatchType", field?.value)
                          return (
                            <div className="w-full">
                              <div className="inline-flex items-center gap-2 ">
                                <input
                                  id={`${tabType}.matchType.${index}.expression`}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      field.onChange(matchType?.value)
                                    } else {
                                      field.onChange(undefined)
                                    }
                                  }}
                                  // checked={field?.value ? true : false}
                                  defaultChecked={field?.value ? true : false}
                                  value={matchType?.value}
                                  type="checkbox"
                                  className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                                />
                                <label
                                  className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                                  htmlFor={`${tabType}.matchType.${index}.expression`}
                                >
                                  {matchType?.label}
                                </label>
                              </div>
                            </div>
                          )
                        }}
                      />
                      <Controller
                        name={`${tabType}.autoCampaignMatchTypes.${index}.bid`}
                        control={control}
                        render={({ field, fieldState }) => (
                          <div className="flex gap-2 flex-col">
                            <div className="w-full relative">
                              <span
                                className={cn(
                                  "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                                  fieldState.error && "border-l !border-red-500"
                                )}
                              >
                                X
                              </span>
                              <input
                                type="number"
                                onChange={(e) => {
                                  field?.onChange(Number(e.target.value));
                                }}
                                value={field?.value || ""}
                                placeholder="Enter multiplier (X)..."
                                className={cn(
                                  "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                                  fieldState.error && "!border-red-500"
                                )}
                              />
                            </div>
                            {fieldState.error && <ErrorLabel className="text-red-500" message={fieldState?.error?.message} />}
                          </div>
                        )}
                      />
                    </>

                  </div>
                ))
              }
            </>
          )
        }

        {/* 
        ####################################################################
        #---------------------------------------------------------
        #    manual campaign specific inputs 
        #---------------------------------------------------------
        #####################################################################
        */}
        {/* broad inputs */}
        {tabType === TabType.MANUAL_CAMPAIGN && (
          watch("manualCampaign.customBid")
            ?
            (
              <div className=" flex gap-2 flex-col">
                <Controller
                  name={`manualCampaign.keywordTargetsMultiplier.broad.selected`}
                  control={control}
                  render={({ field }) => (
                    <div className="w-full">
                      <div className="inline-flex items-center gap-2 ">
                        <input
                          id={`${tabType}.matchType.broad.selected`}
                          checked={field?.value}
                          onChange={(e) => {
                            field?.onChange(!field?.value);
                          }}
                          type="checkbox"
                          className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                        />
                        <label
                          className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                          htmlFor={`${tabType}.matchType.broad.selected`}
                        >
                          Broad
                        </label>
                      </div>
                    </div>
                  )}
                />
                <Controller
                  name={`manualCampaign.keywordTargetsMultiplier.broad.value`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <div className="flex gap-2 flex-col">
                      <div className="w-full relative">
                        <span
                          className={cn(
                            "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                            fieldState.error && "border-l !border-red-500"
                          )}
                        >
                          X
                        </span>
                        <input
                          type="number"
                          onChange={(e) => {
                            field?.onChange(e.target.value);
                          }}
                          value={field?.value || ""}
                          placeholder="Enter multiplier (X)..."
                          className={cn(
                            "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                            fieldState.error && "!border-red-500"
                          )}
                        />
                      </div>
                      {fieldState.error?.message && <ErrorLabel message={fieldState?.error?.message} />}
                    </div>
                  )}
                />
              </div>
            )
            :
            <DisabledInputs inputName="Broad" />
        )}

        {/* phrase inputs */}
        {tabType === TabType.MANUAL_CAMPAIGN && (
          watch("manualCampaign.customBid")
            ?
            <div className=" flex gap-2 flex-col">
              <Controller
                name={`manualCampaign.keywordTargetsMultiplier.phrase.selected`}
                control={control}
                render={({ field }) => (
                  <div className="w-full">
                    <div className="inline-flex items-center gap-2 ">
                      <input
                        id={`manualCampaign.keywordTargetsMultiplier.phrase.selected`}
                        checked={field?.value}
                        onChange={(e) => {
                          field?.onChange(!field?.value);
                        }}
                        type="checkbox"
                        className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                      />
                      <label
                        className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                        htmlFor={`manualCampaign.keywordTargetsMultiplier.phrase.selected`}
                      >
                        Phrase
                      </label>
                    </div>
                  </div>
                )}
              />
              <Controller
                name={`manualCampaign.keywordTargetsMultiplier.phrase.value`}
                control={control}
                render={({ field, fieldState }) => (
                  <div className="flex gap-2 flex-col">
                    <div className="w-full relative">
                      <span
                        className={cn(
                          "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                          fieldState.error && "border-l !border-red-500"
                        )}
                      >
                        X
                      </span>
                      <input
                        type="number"
                        onChange={(e) => {
                          field?.onChange(e.target.value);
                        }}
                        value={field?.value || ""}
                        placeholder="Enter multiplier (X)..."
                        className={cn(
                          "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                          fieldState.error && "!border-red-500"
                        )}
                      />
                    </div>
                    {fieldState.error?.message && <ErrorLabel message={fieldState?.error?.message} />}
                  </div>
                )}
              />
            </div>
            :
            <DisabledInputs inputName="Phrase" />
        )}

        {/* exact inputs */}
        {tabType === TabType.MANUAL_CAMPAIGN && (
          watch("manualCampaign.customBid")
            ?
            <div className=" flex gap-2 flex-col">
              <Controller
                name={`manualCampaign.keywordTargetsMultiplier.exact.selected`}
                control={control}
                render={({ field }) => (
                  <div className="w-full">
                    <div className="inline-flex items-center gap-2 ">
                      <input
                        id={`manualCampaign.keywordTargetsMultiplier.exact.selected`}
                        checked={field?.value}
                        onChange={(e) => {
                          field?.onChange(!field?.value);
                        }}
                        type="checkbox"
                        className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                      />
                      <label
                        className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                        htmlFor={`manualCampaign.keywordTargetsMultiplier.exact.selected`}
                      >
                        Exact
                      </label>
                    </div>
                  </div>
                )}
              />
              <Controller
                name={`manualCampaign.keywordTargetsMultiplier.exact.value`}
                control={control}
                render={({ field, fieldState }) => (
                  <div className="flex gap-2 flex-col">
                    <div className="w-full relative">
                      <span
                        className={cn(
                          "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                          fieldState.error && "border-l !border-red-500"
                        )}
                      >
                        X
                      </span>
                      <input
                        type="number"
                        onChange={(e) => {
                          field?.onChange(e.target.value);
                        }}
                        value={field?.value || ""}
                        placeholder="Enter multiplier (X)..."
                        className={cn(
                          "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                          fieldState.error && "!border-red-500"
                        )}
                      />
                    </div>
                    {fieldState.error?.message && <ErrorLabel message={fieldState?.error?.message} />}
                  </div>
                )}
              />
            </div>
            :
            <DisabledInputs inputName="Exact" />
        )}

        {/* product inputs */}
        {tabType === TabType.MANUAL_CAMPAIGN && (
          watch("manualCampaign.customBid")
            ?
            <div className=" flex gap-2 flex-col">
              <Controller
                name={`manualCampaign.keywordTargetsMultiplier.product.selected`}
                control={control}
                render={({ field }) => (
                  <div className="w-full">
                    <div className="inline-flex items-center gap-2 ">
                      <input
                        id={`manualCampaign.keywordTargetsMultiplier.product.selected`}
                        checked={field?.value}
                        onChange={(e) => {
                          field?.onChange(!field?.value);
                        }}
                        type="checkbox"
                        className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                      />
                      <label
                        className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                        htmlFor={`manualCampaign.keywordTargetsMultiplier.product.selected`}
                      >
                        Product
                      </label>
                    </div>
                  </div>
                )}
              />
              <Controller
                name={`manualCampaign.keywordTargetsMultiplier.product.value`}
                control={control}
                render={({ field, fieldState }) => (
                  <div className="flex gap-2 flex-col">
                    <div className="w-full relative">
                      <span
                        className={cn(
                          "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                          fieldState.error && "border-l !border-red-500"
                        )}
                      >
                        X
                      </span>
                      <input
                        type="number"
                        onChange={(e) => {
                          field?.onChange(e.target.value);
                        }}
                        value={field?.value || ""}
                        placeholder="Enter multiplier (X)..."
                        className={cn(
                          "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                          fieldState.error && "!border-red-500"
                        )}
                      />
                    </div>
                    {fieldState.error?.message && <ErrorLabel message={fieldState?.error?.message} />}
                  </div>
                )}
              />
            </div>
            :
            <DisabledInputs inputName="Product" />
        )}

        {/* category inputs */}
        {tabType === TabType.MANUAL_CAMPAIGN && (
          watch("manualCampaign.customBid")
            ?
            <div className=" flex gap-2 flex-col">
              <Controller
                name={`manualCampaign.keywordTargetsMultiplier.category.selected`}
                control={control}
                render={({ field }) => (
                  <div className="w-full">
                    <div className="inline-flex items-center gap-2 ">
                      <input
                        id={`manualCampaign.keywordTargetsMultiplier.category.selected`}
                        checked={field?.value}
                        onChange={(e) => {
                          field?.onChange(!field?.value);
                        }}
                        type="checkbox"
                        className="accent-[#237804] hover:accent-[#237804] focus:accent-[#237804] h-[15px] w-[15px] cursor-pointer"
                      />
                      <label
                        className="!m-0 text-[#111827] text-[16px] !font-bold leading-6"
                        htmlFor={`manualCampaign.keywordTargetsMultiplier.category.selected`}
                      >
                        Category
                      </label>
                    </div>
                  </div>
                )}
              />
              <Controller
                name={`manualCampaign.keywordTargetsMultiplier.category.value`}
                control={control}
                render={({ field, fieldState }) => (
                  <div className="flex gap-2 flex-col">
                    <div className="w-full relative">
                      <span
                        className={cn(
                          "absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-[#111827] ",
                          fieldState.error && "border-l !border-red-500"
                        )}
                      >
                        X
                      </span>
                      <input
                        type="number"
                        onChange={(e) => {
                          field?.onChange(e.target.value);
                        }}
                        value={field?.value || ""}
                        placeholder="Enter multiplier (X)..."
                        className={cn(
                          "outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12 hover:border-[#237804] focus:border-[#237804]",
                          fieldState.error && "!border-red-500"
                        )}
                      />
                    </div>
                    {fieldState.error?.message && <ErrorLabel message={fieldState?.error?.message} />}
                  </div>
                )}
              />
            </div>
            :
            <DisabledInputs inputName="Category" />
        )}
      </div>
    </div>
  );
};

export default MatchTypeInputs;


const DisabledInputs = ({ inputName }: { inputName: string }) => {
  return (
    <div className="flex gap-2 flex-col opacity-50">
      <div className="w-full">
        <div className="inline-flex items-center gap-2 ">
          <input
            checked={true}
            type="checkbox"
            className="h-[15px] w-[15px] cursor-pointer"
            disabled={true}
            readOnly={true}
            title="Please provide a Targeting Bid first"
          />
          <label
            className="!m-0 text-gray-400 text-[16px] !font-bold leading-6"
          >
            {inputName}
          </label>
        </div>
      </div>
      <div className="flex gap-2 flex-col">
        <div className="w-full relative">
          <span
            className="absolute top-[50%] translate-y-[-50%] right-[1px] px-3.5 py-[8px] rounded-r-[4px] bg-[#F3F4F6] text-gray-400"
          >
            X
          </span>
          <input
            type="number"
            placeholder="Enter multiplier (X)..."
            className="outline-none border-[1px] py-2 px-3 rounded-[4px] w-full pr-12"
            disabled={true}
            readOnly={true}
            title="Please provide a Targeting Bid first"
          />
        </div>
      </div>
    </div>
  )
}