import styled from "@emotion/styled";
import { Radio } from "@mui/material";
import SelectedNegativeProductsRow from "./SelectedNegativeProductsRow";
import { FC } from "react";
import { TabType } from "pages/createAds/CreateAds.types";
import CreateFormStateTypes, { NegativeApplyToEnums } from "pages/createAds/CreateAdsFormValidationSchema";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import NegativeTargetingTextarea from "../NegativeTargetingTextarea";
import stringToArray from "utils/stringToArray";
import getKeyByValue from "utils/getKeyByValue";

const ContentBoxContainer = styled("div")(() => ({
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#aaa",
  },
}));

const applyToOptions = [{ label: "Campaign", value: NegativeApplyToEnums.campaign }, { label: "Ad Group", value: NegativeApplyToEnums.adGroup }]

// Reverse lookup function
// function getKeyByValue<T extends object>(enumObject: T, value: string): string {
//   return Object.keys(enumObject).find((key) => enumObject[key as keyof T] === value) || "";
// }

const NegativeProductsTab: FC<{ campTabType: TabType }> = ({ campTabType }) => {
  /**-Hook Form-**/
  const { control, getValues, resetField, watch } = useFormContext<CreateFormStateTypes>()
  const { append } = useFieldArray({ control: control, name: `${campTabType}.negativeProducts.expression` })
  const negativeProducts = watch(`${campTabType}.negativeProducts`)

  /**-Event Handlers-**/
  const handleAddKeywordClick = () => {
    const createAdsFormValues: CreateFormStateTypes = getValues()
    const campaign = createAdsFormValues[`${campTabType}`] // auto or manual campaign
    const asinsString: string = campaign?.negativeTargetingInputs?.product || ''
    const prevNegTargetingAsins = campaign?.negativeProducts?.expression || [] //previously added asins to compare with current asins

    const asins = stringToArray("product", asinsString, prevNegTargetingAsins)

    const productAsins = asins?.map(asin => {
      const expression = {
        type: "ASIN_SAME_AS",
        value: asin
      }
      return expression
    })

    append(productAsins)
    resetField(`${campTabType}.negativeTargetingInputs.product`)
  }

  return (
    <div className="grid gap-3">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-end">
        <div className="flex gap-x-6 items-center flex-wrap">
          <div className="flex flex-col gap-2">
            <h4 className="text-[#111827] !font-bold !m-0 leading-6 text-[16px]">
              Apply to
            </h4>
            <div className="flex gap-4 items-center">
              {
                applyToOptions?.map(applyTo => (
                  <div key={applyTo.label} className="flex items-center justify-start">
                    <Controller
                      control={control}
                      name={`${campTabType}.negativeProducts.applyTo`}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Radio
                            id={`${campTabType}.negativeProducts.applyTo.${applyTo?.label}`}
                            sx={{
                              color: "#6B7280",
                              padding: "0px",
                              margin: "0px",
                              marginRight: "5px",
                              "&.Mui-checked": {
                                color: "#237804",
                              },
                            }}
                            onChange={onChange}
                            value={applyTo?.value}
                            checked={value === applyTo?.value ? true : false}
                          />
                        )
                      }}
                    />
                    <label htmlFor={`${campTabType}.negativeProducts.applyTo.${applyTo?.label}`}>{applyTo?.label}</label>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between">
          <p className="text-[#6B7280] text-[16px] !font-medium !m-0 leading-6">
            {negativeProducts?.expression?.length || 0} keyword added
          </p>
          <button
            type="button"
            className="text-[#D1D5DB] text-[16px] !font-medium leading-6"
          >
            Remove all
          </button>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 items-end">
        {/* left box */}
        <div className="flex flex-col gap-2 h-full ">
          {/* box header */}

          {/* keywords inputs button */}
          <div className="w-full flex flex-col gap-4">
            <NegativeTargetingTextarea
              campTabType={campTabType}
              targetingType="product"
            />

            {/* keyword  add info for small screen */}
            <div className="flex">
              <div className="w-full flex justify-between">
                <p>You can enter up to 1000 keywords</p>
                <button
                  type="button"
                  className="text-[#237804] bg-[#CAFCD0] rounded-[3px] py-2 px-4 font-bold"
                  onClick={handleAddKeywordClick}
                >
                  Add Keyword
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* right blox */}
        <div className="flex flex-col gap-2 h-full">
          {/* keywords inputs button */}
          <div className="w-full flex flex-col">
            <div className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 h-[200px] overflow-x-hidden overflow-y-auto flex flex-col gap-2">
              {/* row header */}
              <div className="grid grid-cols-3">
                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  ASINs
                </div>
                <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                  Apply to
                </div>
              </div>
              <ContentBoxContainer className="w-full flex-grow flex flex-col gap-y-3 overflow-x-hidden overflow-y-auto">
                {
                  negativeProducts?.expression?.map((expression, index) => (
                    <SelectedNegativeProductsRow
                      key={index}
                      asin={expression?.value as string}
                      applyTo={getKeyByValue(NegativeApplyToEnums, negativeProducts?.applyTo || '')}
                      campTabType={campTabType}
                    />
                  ))
                }
              </ContentBoxContainer>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default NegativeProductsTab;
