import styled from "@emotion/styled";
import { CiSearch } from "react-icons/ci";
import CategoryRow from "./CategoryRow";
import SelectedCategoryProductRow from "./SelectedCategoryProductRow";
import { useFieldArray, useFormContext } from "react-hook-form";
import CreateFormStateTypes, { ProductTargetingCluasesInterface, ProductTargetingExpressionsTypes } from "pages/createAds/CreateAdsFormValidationSchema";
import { DefaultBid } from "constants/index";
const ContentBoxContainer = styled("div")(() => ({
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#aaa",
  },
}));

export interface CategoryInterface {
  id: string,
  categoryId: string,
  name: string
}

const categories: CategoryInterface[] = [
  { id: "1", categoryId: "1", name: "Coloring Books for Grown-Ups" },
  { id: "2", categoryId: "2", name: "Coloring Books for Kids" },
  { id: "3", categoryId: "3", name: "Coloring Books for Teens" },
  { id: "4", categoryId: "4", name: "Coloring Books for Grown-Ups" },
  { id: "5", categoryId: "5", name: "Coloring Books for Grown-Ups" },
]

const CategoriesTab = () => {
  /**-Hook Form-**/
  const { control, getValues, watch } = useFormContext<CreateFormStateTypes>()
  const { append, remove } = useFieldArray({
    control: control,
    name: "manualCampaign.targetingClauses"
  })

  const allTargetingClauses = watch("manualCampaign.targetingClauses") || []
  const productCatTargets = allTargetingClauses?.filter(cat => cat?.expression?.[0]?.type === ProductTargetingExpressionsTypes.ASIN_CATEGORY_SAME_AS)

  /**-Event handlers-**/
  const handleCategorySelectionChange = (category: CategoryInterface): void => {
    const allSelectedCategories = getValues("manualCampaign.targetingClauses")
    const categoryFound = allSelectedCategories?.some(target => target?.id === category?.id && category?.categoryId === target?.expression?.[0]?.value && target?.expression?.[0]?.type === ProductTargetingExpressionsTypes.ASIN_CATEGORY_SAME_AS)
    if (categoryFound) {
      const index = allSelectedCategories?.findIndex(target => target?.id === category?.id && category?.categoryId === target?.expression?.[0]?.value && target?.expression?.[0]?.type === ProductTargetingExpressionsTypes.ASIN_CATEGORY_SAME_AS)
      remove(index)
    } else {
      let bid = Number(getValues("manualCampaign.customBid")) || Number(getValues("manualCampaign.defaultBid")) || DefaultBid
      const multiplierPath = `manualCampaign.keywordTargetsMultiplier.category.value` as keyof CreateFormStateTypes;
      const multiplier2 = Number(getValues(multiplierPath))
      const newCategory: ProductTargetingCluasesInterface = {
        id: category?.id,
        name: category?.name,
        expression: [{ type: ProductTargetingExpressionsTypes.ASIN_CATEGORY_SAME_AS, value: category?.categoryId }],
        bid: bid
      }
      if (multiplier2) {
        bid = bid * (multiplier2 || 1)
        newCategory.bid = bid
      }
      append(newCategory)
    }
  }

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 items-end">
      {/* left box */}
      <div className="flex flex-col gap-2 h-full ">
        {/* box header search input*/}
        <div className="flex gap-x-6 items-center relative ">
          <input
            className="w-full outline-none border-[1px] py-2 px-4 pr-9 rounded-[4px]"
            placeholder="Search categories..."
          />
          <span className="absolute right-0 top-[50%] translate-y-[-50%] px-3 text-[#737373]">
            <CiSearch className="h-5 w-5" />
          </span>
        </div>
        {/* keywords inputs button */}
        <div className="w-full flex flex-col gap-4">
          <div className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 h-[200px] overflow-x-hidden overflow-y-auto flex flex-col gap-2">
            {/* row header */}
            <div className="grid grid-cols-4">
              <div className=" col-span-2 text-[#9CA3AF] font-medium leading-6 text-[16px]">
                Categories
              </div>
              <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                Marketplace
              </div>
              <div className="text-[#9CA3AF] font-medium leading-6 text-[16px] text-center">
                Add All
              </div>
            </div>
            <ContentBoxContainer className="w-full flex-grow flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
              {
                categories?.map((category, index) => (
                  <CategoryRow
                    key={index}
                    category={category}
                    handleCategorySelectionChange={handleCategorySelectionChange}
                  />
                ))
              }
            </ContentBoxContainer>
          </div>
          {/* ----------- */}
        </div>
      </div>
      {/* right blox */}
      <div className="flex flex-col gap-2 h-full items-end justify-end">
        <div className="w-full flex justify-between">
          <p className="text-[#6B7280] text-[16px] !font-medium !m-0 leading-6">
            {productCatTargets?.length || 0}/1000 category and product added
          </p>
          <button
            type="button"
            className="text-[#D1D5DB] text-[16px] !font-medium leading-6"
          >
            Remove all
          </button>
        </div>
        {/* keywords inputs button */}
        <div className="w-full flex flex-col">
          <div className="w-full border-[1px] outline-none rounded-[4px] py-3 px-2.5 h-[200px] overflow-x-hidden overflow-y-auto flex flex-col gap-2">
            {/* row header */}
            <div className="grid grid-cols-4">
              <div className=" col-span-2 text-[#9CA3AF] font-medium leading-6 text-[16px]">
                Category & Product
              </div>
              <div className="text-[#9CA3AF] font-medium leading-6 text-[16px]">
                Marketplace
              </div>
            </div>
            <ContentBoxContainer className="w-full flex-grow flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
              {/* content type  */}
              <div className="w-full text-[#9CA3AF] font-medium leading-6 text-xs">
                {true ? "Category" : "Product"}
              </div>
              {
                productCatTargets.map((productCat, index) => (

                  <SelectedCategoryProductRow
                    key={index}
                    category={productCat}
                  />
                ))
              }
            </ContentBoxContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesTab;
